<template>
  <div>
    <v-form ref="form">
      <div class="imgUserLogin mb-5">
        <img :src="imgLogo" alt="" />
      </div>
      <div class="text-center mb-5">
        <h1 class="titleLoginInicio">SUCURSAL</h1>
      </div>
      <v-select
        solo
        :items="branch"
        v-model="idbranch"
        label="Sucursal"
        item-text="trade_name"
        item-value="id"
      ></v-select>
      <!-- <v-text-field
        dark
        autocomplete="false"
        filled
        rounded
        dense
        v-model="formulario.clave"
        label="Contraseña"
        type="password"
      >
      </v-text-field> -->
      <div class="divBtnLoginOk">
        <v-btn
          @click="validate()"
          rounded
          elevation="0"
          class="btnLoginOk"
          block
        >
          Continuar
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import imgUser from "../../../public/assets/img/login/user.svg";
import imgLogo from "../../../public/assets/img/login/logo.svg";
import imgOnda from "../../../public/assets/img/login/onda-top.png";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "branchComponent",
  data: () => ({
    formulario: {},
    imgUser: imgUser,
    imgLogo: imgLogo,
    imgOnda: imgOnda,
    idbranch: null,
  }),
  mounted() {
    this.cargarBranch();
    if (!JSON.parse(localStorage.getItem("datos"))) {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapState(["branch"]),
  },
  methods: {
    ...mapActions(["cargarBranch"]),
    validate() {
      if (this.idbranch) {
        localStorage.setItem("branch", JSON.stringify(this.idbranch));
        localStorage.setItem(
          "iso_pais",
          JSON.stringify(
            this.branch.filter((v) => v.id == this.idbranch)[0].iso_pais
          )
        );
        this.$router.push("/listado");
      } else {
        Swal.fire({
          icon: "warning",
          title: "Seleccione una Sucursal",
        });
      }
    },
  },
};
</script>

<style>
.btnLoginOk {
  background: rgb(89 240 255) !important;
}

.backLoginOndas {
  background: url("../../../public/assets/img/login/onda-top.png");
}

.divBtnLoginOk {
  padding: 0px 30%;
}

.imgUserLogin {
  text-align: center;
  margin: auto;
  background: white;
  height: 225px;
  width: 225px;
  border-radius: 50%;
  padding: 65px 0px 0px 0px;
}

.imgUserLogin img {
  max-width: 80%;
}

.titleLoginInicio {
  color: white;
}

@media (max-width: 600px) {
  .divBtnLoginOk {
    padding: 0px 0px;
  }

  .imgUserLogin img {
    max-width: 80%;
  }
}
.theme--light.v-application {
  background: white;
}
</style>
